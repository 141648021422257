export const menu = [
    {
        "name": "Home",
        "url": "/",
        "options": []
    },
    {
        "name": "About Us",
        "url": "/aboutus",
        "options": [
            {
                "name": "Profile and Organization",
                "url": "/profile-organization"
            },
            {
                "name": "History",
                "url": "/history"
            },
            {
                "name": "Key Management",
                "url": "/key-management"
            }
        ]
    },
    {
        "name": "Crop Solution",
        "url": "/crop-solution",
        "options": [
            {
                "name": "Insecticides",
                "url": "/insecticides"
            },
            {
                "name": "Fungicide",
                "url": "/fungicide"
            },
            {
                "name": "Herbicide",
                "url": "/herbicide"
            }
        ]
    },
    {
        "name": "Blog",
        "url": "/blog",
        "options": []
    }
];