import React from 'react';


function NotFound(){
    return (
        <>
            Page under development.
        </>
        
    )
}
export default NotFound;